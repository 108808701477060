const TwitterIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3535_1221)">
        <path d="M24.0298 5.01102C23.1429 5.40477 22.1923 5.66914 21.1929 5.78914C22.2129 5.17789 22.9967 4.21039 23.3642 3.05914C22.4117 3.62352 21.3523 4.03414 20.2292 4.25539C19.3292 3.29539 18.0467 2.69727 16.6273 2.69727C13.9011 2.69727 11.6904 4.90602 11.6904 7.63227C11.6904 8.01852 11.7354 8.39539 11.8198 8.75727C7.71731 8.55102 4.08168 6.58602 1.64605 3.59914C1.22043 4.33039 0.978555 5.17789 0.978555 6.08164C0.978555 7.79352 1.84855 9.30477 3.17418 10.1916C2.36418 10.1654 1.60293 9.94227 0.937305 9.57289C0.937305 9.59352 0.937305 9.61414 0.937305 9.63477C0.937305 12.0273 2.6398 14.0204 4.89543 14.476C4.48293 14.5885 4.04605 14.6485 3.59606 14.6485C3.2773 14.6485 2.96793 14.6185 2.66605 14.5585C3.29606 16.5179 5.11856 17.9485 7.27668 17.9879C5.58918 19.3116 3.46106 20.0991 1.1473 20.0991C0.74793 20.0991 0.356055 20.0766 -0.0283203 20.0298C2.15418 21.4323 4.74918 22.2479 7.5373 22.2479C16.6161 22.2479 21.5811 14.7273 21.5811 8.20602C21.5811 7.99227 21.5754 7.77664 21.5679 7.56664C22.5317 6.87102 23.3679 6.00102 24.0298 5.01102Z" />
      </g>
    </svg>
  );
};

export default TwitterIcon;
